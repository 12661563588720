
import React from 'react';

import Layout from '../components/body/layout';
import Head from '../components/header/head';

import mainText from './index.module.scss';

const IndexPage = () => {
    return (
        <Layout>
            <Head title="Home" />
            <div className={mainText.text}>
                <h1>J.Jansen</h1>
                <h2>Web developer from Belgium</h2>
            </div>
        </Layout>
    )
}

export default IndexPage;
